import React, { Component } from 'react';
import '../css/App.css';

import ViewDorms from './ViewDorms';
import Navigation from './Navigation';
import ViewFloors from './ViewFloors';
import ViewRoom from './ViewRoom';
import ViewUser from './ViewUser';
import ListUsers from './ListUsers';
import AddUser from './AddUser';

import { without } from 'lodash';
import { findIndex } from 'lodash';

class App extends Component {

  constructor() {
    super();
    this.state = {
      viewState: 'ViewDorms',
      dormUsers:[],
      dormUnits:[],
      dormNumber: 0,
      roomData: {},
      userInfo: {},
    }
    this.toggleViewDorms = this.toggleViewDorms.bind(this);
    this.toggleViewFloors = this.toggleViewFloors.bind(this);
    this.toggleViewRoom = this.toggleViewRoom.bind(this);
    this.toggleViewUser = this.toggleViewUser.bind(this);
    this.toggleViewListUsers = this.toggleViewListUsers.bind(this);
    this.toggleViewAddUser = this.toggleViewAddUser.bind(this);
  }


  toggleViewDorms(){
    this.setState({
      dormNumber: 0,
      viewState: 'ViewDorms'
    })
  }

  toggleViewFloors(dnum){
    this.setState({
      dormNumber: dnum,
      viewState: 'ViewFloors'
    })
  }

  toggleViewRoom(room){
    this.setState({
      roomData: room,
      viewState: 'ViewRoom'
    })
  }

  toggleViewUser(user){
    this.setState({
      userInfo: user,
      viewState: 'ViewUser'
    })
  }

  toggleViewListUsers(){
    this.setState({
      viewState: 'ViewListUsers'
    })
  }

  toggleViewAddUser(){
    this.setState({
      viewState: 'ViewAddUser'
    })
  }


  componentDidMount() {
    fetch('https://symfony.dorm.itsgeni.us/users')
      .then(response => response.json())
      .then(result => {
        const users = result.data.map(user => {
          return user;
        })
        this.setState({
          dormUsers: users
        });
      });

    fetch('https://symfony.dorm.itsgeni.us/unit')
      .then(response => response.json())
      .then(result => {
        const units = result.data.map(unit => {
          return unit;
        })
        this.setState({
          dormUnits: units
        });
      });

      
  }
  
  
  
  render() {

    return (
      <div className="">
        <Navigation 
        toggleViewDorms={this.toggleViewDorms}
        toggleViewListUsers={this.toggleViewListUsers}
        toggleViewAddUser={this.toggleViewAddUser}
        viewState = {this.state.viewState}
        />
        
      <main className="page bg-white" id="dorm">
        
      <div className="container">
        <div className="row">
          <div className="col-md-12 bg-white">
            <div className="container">
              
              <ViewDorms 
                toggleViewFloors={this.toggleViewFloors}
                viewState = {this.state.viewState} />
              
              <ViewFloors 
                toggleViewRoom={this.toggleViewRoom}
                dormNumber = {this.state.dormNumber}
                dormUnits = {this.state.dormUnits}
                viewState = {this.state.viewState} />
              <ViewRoom 
                toggleViewUser={this.toggleViewUser}
                roomData = {this.state.roomData}
                viewState = {this.state.viewState} />
              <ViewUser 
                userInfo = {this.state.userInfo}
                viewState = {this.state.viewState} />
              <ListUsers />
              <AddUser />
            </div>
          </div>
        </div>
      </div>
    </main>
    </div>
    );
  }
}

export default App;
