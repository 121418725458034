import React, { Component } from 'react';

class ViewFloors extends Component {
    
            
        createFloors = () => {
            
            let floors = []; 
            for(let f=1; f<=4;f++){
            let rooms = [];
            this.props.dormUnits.map(unit => {
                if(this.props.dormNumber === unit.building_number){
                    if(unit.floor_number === f){
                        rooms.push(
                            <div className="p-2 unit" 
                                key={unit.id} 
                                onClick={() => this.props.toggleViewRoom(unit)}
                                >
                                <img src="/img/jo9ygt7a3.svg" alt="" />
                                <p className="room_number">
                                    Room: {unit.room_number}<br />
                                    Occupants: {unit.users.length}</p>
                            </div>
                            ) 
                    }
                    
                    
                    }
                return rooms;
                })
                floors.push(
                    <div key={ "floor_" + f}>
                        <h3>Floor: {f}</h3>
                    <div className="d-flex text-center just-content-between align-items-center">
                        
                        {rooms}
                    </div>
                    </div>
                )
               
            }
            return floors;
        
    }
   
            
    
    render() {
        if(this.props.viewState === "ViewFloors") {
            return (
                <div id="floors">
                    
                    <div className="d-flex flex-column text-center just-content-between align-items-center">
                    <h1>Dorm: {this.props.dormNumber}</h1>
                        <div className="p-2">
                            {this.createFloors()}
                        </div>
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }
}

export default ViewFloors;
