import React, { Component } from 'react';

class ViewDorms extends Component {
    render() {
        if(this.props.viewState === "ViewDorms") {
            return (
                <div id="dorms">
                    <div className="d-flex justify-content-between align-items-center">
                        {[1,2].map(dorm => (
                        <div className="p-2 text-center dorm" key={dorm} data-dorm={dorm}
                            onClick={() => this.props.toggleViewFloors(dorm)}>
                            <h3>Dorm {dorm}</h3>
                            <span id={"building_" + dorm + "_img"} >
                                    <img src="https://cdn.pixabay.com/photo/2017/01/31/18/29/building-2026265_960_720.png"  alt="" />
                            </span>
                            
                        </div>
                        ))}
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }
}


export default ViewDorms;
