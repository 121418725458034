import React, { Component } from 'react';
import Moment from 'react-moment';
class ViewUser extends Component {
    render() {
        if(this.props.viewState === "ViewUser") {
            return (
                <div>
                    <div className="d-flex flex-column">
                        <h1></h1>
                        <div id="unit-info" className="p-2">
                            <div className="row">
                                <div className="col">Name:</div>
                                <div className="col">{this.props.userInfo.name}</div>
                            </div>
                            <div className="row">
                                <div className="col">Email:</div>
                                <div className="col">{this.props.userInfo.email}</div>
                            </div>
                            <div className="row">
                                <div className="col">Address:</div>
                                <div className="col">
                                    {this.props.userInfo.street}<br />
                                    {this.props.userInfo.city}, {this.props.userInfo.state} {this.props.userInfo.zip}<br />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">Phone:</div>
                                <div className="col">
                                    {this.props.userInfo.phone}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">Gender:</div>
                                <div className="col">
                                    {this.props.userInfo.street}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">Date of birth:</div>
                                <div className="col">
                                    <Moment 
                                        date={this.props.userInfo.dob}
                                        parse="YYYY-MM-DD hh:mm"
                                        format="MMM D, YYYY"
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">Student ID:</div>
                                <div className="col">
                                    {this.props.userInfo.student_id}
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
            );
        } else {
            return null;
        }
        
    }
}

export default ViewUser;