import React, { Component } from 'react';

class AddUser extends Component {
    
    render() {
        if(this.props.viewState === "ViewAddUser") {
        return null;
        
        } else {
            return null;
        }
    }
}

export default AddUser;