import React, { Component } from 'react';

class ViewRoom extends Component {
    
    createRoom = () => {
        let userData = [];
        this.props.roomData.users.map(user => {
            userData.push(
                <div className="row student" key={user.id} 
                onClick={() => this.props.toggleViewUser(user)}>
                    <div className="col">Name: {user.name}</div>
                    <div className="col">Email: {user.email}</div>
                </div>
            );
        });
        return userData;
    }
    render() {
        if(this.props.viewState === "ViewRoom") {
            return (
                <div id="unit">
                    <div className="d-flex flex-column">
                        <h1>Dorm: {this.props.roomData.building_number} - Room: {this.props.roomData.room_number}</h1>
                        <div id="unit-info" className="p-2">
                            {this.createRoom()}
                        </div>
                    </div>
                </div> 
            );
        } else {
            return null;
        }
        
    }
}

export default ViewRoom;
