import React, { Component } from 'react';

class Navigation extends Component {
    render() {
        return (
        <header className="text-white">
            <div className="container">
            <nav className="navbar navbar-expand-lg">
                <h4 className="navbar-brand">Dorm Management</h4>
                <div className="collapse navbar-collapse">
                    
                    <ul className="navbar-nav">
                        <li className="navbar-item"
                            onClick={() => this.props.toggleViewDorms()}
                            ><span className="nav-link">View Dorms</span></li>
                        <li className="navbar-item"><span className="nav-link"
                            onClick={() => this.props.toggleViewListUsers()}
                            >List Users</span></li>
                        <li className="navbar-item"><span className="nav-link"
                            onClick={() => this.props.toggleViewAddUser()}
                            >Add User</span></li>
                    </ul>
                </div>
            </nav>
            </div>
        </header>
      );
        
    }
}

export default Navigation;