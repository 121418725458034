import React, { Component } from 'react';

class ListUsers extends Component {
    render() {
        if(this.props.viewState === "ViewListUsers") {
        return (
            <div className="container">
                
            </div>
        );
        } else {
            return null;
        }
    }
}

export default ListUsers;